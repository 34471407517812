import { qsOptional, qsRequired, targetRequired } from '@/scripts/functions'
import { type StickyHeader } from '@/scripts/layout/StickyHeader'
import { UcoastEl } from '@/scripts/core/UcoastEl'

export class DetailsDisclosure extends UcoastEl {
	static htmlSelector = 'details-disclosure'
	mainDetailsToggle: HTMLDetailsElement
	content: HTMLElement
	mainSummaryEl: HTMLElement
	animations: Animation[]
	constructor() {
		super()
		this.mainDetailsToggle = qsRequired('details', this)
		this.content = qsRequired('summary', this.mainDetailsToggle, 'nextElementSibling')
		this.mainSummaryEl = qsRequired('summary', this.mainDetailsToggle)
		this.animations = []
		this.mainDetailsToggle.addEventListener('focusout', this.onFocusOut.bind(this))
		this.mainDetailsToggle.addEventListener('toggle', this.onToggle.bind(this))
	}

	onFocusOut() {
		setTimeout(() => {
			if (!this.contains(document.activeElement)) this.close()
		})
	}

	onToggle() {
		if (!this.animations.length) this.animations = this.content.getAnimations()

		if (this.mainDetailsToggle.hasAttribute('open')) {
			this.animations.forEach((animation) => animation.play())
		} else {
			this.animations.forEach((animation) => animation.cancel())
		}
	}

	close() {
		this.mainDetailsToggle.removeAttribute('open')
		this.mainDetailsToggle.setAttribute('closed', '')
		this.mainSummaryEl.setAttribute('aria-expanded', 'false')
	}
}

export class HeaderMenu extends DetailsDisclosure {
	static override htmlSelector = 'header-menu'
	header?: StickyHeader
	stickyContainer?: HTMLElement

	constructor() {
		super()
		this.header = qsOptional<StickyHeader>('[data-uc-header-wrapper]')
		if (this.header) {
			this.stickyContainer = qsOptional('.shopify-section-header-sticky')
		}
		this.mainDetailsToggle.addEventListener('mouseenter', this.onMouseEnter.bind(this))
		this.mainDetailsToggle.addEventListener('mouseleave', this.onMouseLeave.bind(this))
		this.content.addEventListener('mouseleave', this.onMouseLeave.bind(this))
	}

	override connectedCallback() {
		super.connectedCallback()
		if (this.isScrolledPastHeader() && this.stickyContainer) {
			this.stickyContainer.classList.add('scrolled-past-header')
		}
	}

	onMouseEnter() {
		this.mainDetailsToggle.removeAttribute('closed')
		this.mainDetailsToggle.setAttribute('open', '')
		this.mainSummaryEl.setAttribute('aria-expanded', 'true')
		this.onToggle()
		if (!this.isScrolledPastHeader() && this.stickyContainer) {
			this.stickyContainer.classList.add('scrolled-past-header')
		}
	}

	onMouseLeave(event: Event) {
		const target = targetRequired(event)
		if (this.content.contains(target) || this.mainSummaryEl.contains(target)) return
		this.mainDetailsToggle.removeAttribute('open')
		this.mainDetailsToggle.setAttribute('closed', '')
		this.mainSummaryEl.setAttribute('aria-expanded', 'false')
		this.onToggle()
		if (!this.isScrolledPastHeader() && this.stickyContainer) {
			this.stickyContainer.classList.remove('scrolled-past-header')
		}
	}

	isScrolledPastHeader() {
		if (!this.header || !this.stickyContainer) return false
		const announcementHeight = this.header.announcement?.getBoundingClientRect().height ?? 0
		return window.scrollY > announcementHeight
	}

	override onToggle() {
		if (!this.header) return
		if (!this.animations.length) this.animations = this.content.getAnimations()

		if (this.mainDetailsToggle.hasAttribute('open')) {
			this.animations.forEach((animation) => {
				animation.play()
			})
		} else {
			this.animations.forEach((animation) => {
				animation.cancel()
			})
		}
		if (
			document.documentElement.style.getPropertyValue('--header-bottom-position-desktop') !==
			''
		)
			return
		document.documentElement.style.setProperty(
			'--header-bottom-position-desktop',
			`${Math.floor(this.header.getBoundingClientRect().bottom)}px`
		)
	}
}
